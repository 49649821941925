<template>
  <div id="registration" class="">
    <v-dialog
      v-model="dialog"
      class="reg-dialog"
      width="500"
      transition="dialog-top-transition"
      persistent
    >
      <v-card>
        <v-card-title class="text-center dialog-title justify-center">
          <span class="">دخول مستخدم </span>

          <span class="icon">
            <v-btn icon @click="close()">
              <v-icon>close</v-icon>
            </v-btn>
          </span>
        </v-card-title>

        <v-divider></v-divider>
        <v-card-text class="mt-6">
          <v-form
            @submit.prevent="submit"
            v-model="valid"
            class="reg-form"
            ref="form"
          >
            <v-row>
              <v-col cols="12" class="py-0">
                <label for="name" class="label">الاسم</label>
                <v-text-field
                  label=""
                  v-model.trim="registrationObj.name"
                  :rules="[validationRules.required]"
                  @focus="deleteValidation()"
                  outlined
                  dense
                ></v-text-field>

                <p
                  class="error--text back-error"
                  v-if="validationErrors && validationErrors.name"
                >
                  {{ validationErrors.name[0] }}
                </p>
              </v-col>
              <v-col cols="12" class="py-0">
                <label for="email" class="label">البريد الالكترونى</label>
                <v-text-field
                  v-model.trim="registrationObj.email"
                  label=""
                  :rules="[validationRules.required, validationRules.email]"
                  @focus="deleteValidation()"
                  outlined
                  dense
                ></v-text-field>
                <p
                  class="error--text back-error"
                  v-if="validationErrors && validationErrors.email"
                >
                  {{ validationErrors.email[0] }}
                </p>
              </v-col>

              <v-col cols="12" class="py-0">
                <label for="mobile" class="label">رقم الجوال</label>
                <v-text-field
                  v-model.trim="registrationObj.mobile"
                  label=""
                  :rules="[validationRules.required, validationRules.numeric]"
                  @focus="deleteValidation()"
                  outlined
                  dense
                ></v-text-field>

                <p
                  class="error--text back-error"
                  v-if="validationErrors && validationErrors.mobile"
                >
                  {{ validationErrors.mobile[0] }}
                </p>
              </v-col>
              <v-col cols="12" class="py-0">
                <label for="pass" class="label">الباسورد</label>
                <v-text-field
                  v-model.trim="registrationObj.password"
                  label=""
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  :rules="[validationRules.required]"
                  @click:append="show = !show"
                  @focus="deleteValidation()"
                  outlined
                  dense
                ></v-text-field>

                <p
                  class="error--text back-error"
                  v-if="validationErrors && validationErrors.password"
                >
                  {{ validationErrors.password[0] }}
                </p>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center py-6">
          <!-- <v-spacer></v-spacer> -->
          <v-btn class="main-btn" text @click="submit"> تسجيل </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "../mixins/validationMixin";
import EventService from "../Services/EventService";
export default {
  name: "Registration",
  mixins: [validationMixin],
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      valid: false,
      show: false,
      registrationObj: {
        name: "",
        email: "",
        mobile: "",
        password: "",
      },
      validationErrors: {},
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },

    submit() {
      if (this.valid == false) {
        this.validate();
      } else {
        EventService.postEvent("/register", this.registrationObj).then(
          (response) => {
            if (response.data.status.error == true) {
              this.validationErrors = response.data.status.validation_errors;
            } else {
              this.close();
            }
          }
        );
      }
    },
    deleteValidation() {
      this.validationErrors = {};
    },
  },
};
</script>

<style lang="scss">
@import "../assets/css/global.scss";

.dialog-title {
  font-size: 22px !important;
  color: #3e546d !important;
  position: relative;
  font-weight: 700 !important;

  .icon {
    position: absolute;
    left: 20px;
  }
}

.reg-form {
  // margin-top: 20px;

  .label {
    color: $blue-text !important;
    font-weight: 700 !important;
  }

  .back-error {
    margin-top: -20px !important;
  }
}
</style>
