<template>
  <div>
    <v-row>
      <v-col cols="12" sm="10" offset-sm="1" class="register-section mb-12">
        <v-row>
          <v-col cols="12" sm="6" class="">
            <div class="form-section">
              <h2 class="text-center mb-3 main-color contract-title">عقود</h2>

              <div class="order-details">
                <p class="name">
                  <strong class="main-color d-inline-block ml-3">
                    اسم العقد:
                  </strong>
                  <span class="gray-color">{{ contract.contract_name }}</span>
                </p>
                <p class="price">
                  <strong class="main-color d-inline-block ml-3">
                    السعر:
                  </strong>
                  <span class="gray-color"
                    >{{ contract.amount }} ريال سعودى</span
                  >
                </p>
              </div>
              <v-form
                ref="form"
                id="payment_form"
                class="payment-form"
                action="https://api.moyasar.com/v1/payments.html"
                method="POST"
                v-model="valid"
              >
                <input
                  type="hidden"
                  name="callback_url"
                  :value="'https://oqoud.shifters.tech/thank-you/' + paymentId"
                />
                <input
                  type="hidden"
                  name="publishable_api_key"
                  value="pk_test_wN6smrbt6zp7HvaHz56KenxVSihwpVefQbJft7RK"
                />
                <input
                  type="hidden"
                  name="amount"
                  :value="parseInt(amount) * 100"
                />

                <input type="hidden" name="description" value="Oqoud Payment" />
                <input type="hidden" name="source[type]" value="creditcard" />
                <v-row>
                  <v-col cols="12" class="py-0">
                    <label for="payment">طريقة الدفع</label>
                    <v-radio-group
                      row
                      v-model="paymentData.payment_method"
                      :rules="[validationRules.required]"
                    >
                      <v-radio label="فيزا" value="visa">
                        <template v-slot:label>
                          <img
                            src="../assets/images/visa.jpg"
                            width="80px"
                            alt="visa"
                            class="payment-method"
                            :class="
                              paymentData.payment_method == 'visa'
                                ? 'active'
                                : ''
                            "
                          />
                        </template>
                      </v-radio>
                      <v-radio label="masterCard" value="mastercard">
                        <template v-slot:label>
                          <img
                            style="height: 56px"
                            src="../assets/images/mastercard.png"
                            width="80px"
                            alt="visa"
                            class="payment-method"
                            :class="
                              paymentData.payment_method == 'mastercard'
                                ? 'active'
                                : ''
                            "
                          />
                        </template>
                      </v-radio>
                      <v-radio label="mada" value="mada">
                        <template v-slot:label>
                          <img
                            style="width: 80px; height: 50px"
                            src="../assets/images/mada.png"
                            width="100px"
                            alt="visa"
                            class="payment-method"
                            :class="
                              paymentData.payment_method == 'mada'
                                ? 'active'
                                : ''
                            "
                          />
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <label for="name">رقم البطاقة *</label>
                    <v-text-field
                      name="source[number]"
                      :rules="[
                        validationRules.required,
                        validationRules.numeric,
                        validationRules.maxLength16,
                      ]"
                      v-model="paymentData.card_number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <label for="name">اسم حامل البطاقة ثلاثى *</label>
                    <v-text-field
                      name="source[name]"
                      v-model="paymentData.card_holder_name"
                      :rules="[
                        validationRules.required,
                        validationRules.spaces,
                        checkcardName(paymentData.card_holder_name),
                      ]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <label for="name">CVV *</label>
                    <v-text-field
                      name="source[cvc]"
                      :rules="[
                        validationRules.required,
                        validationRules.numeric,
                        validationRules.maxLength3,
                      ]"
                      v-model="paymentData.cvc"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <label for="month">الشهر *</label>
                    <v-select
                      name="source[month]"
                      :items="months"
                      :rules="[
                        validationRules.required,
                        checkDate(paymentData.exp_year, paymentData.exp_month),
                      ]"
                      v-model="paymentData.exp_month"
                    ></v-select>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <label for="year">السنه *</label>
                    <v-select
                      name="source[year]"
                      :rules="[
                        validationRules.required,
                        checkDate(paymentData.exp_year, paymentData.exp_month),
                      ]"
                      :items="years"
                      v-model="paymentData.exp_year"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" class="text-center">
                    <v-btn class="main-btn" @click="submitCheckout">
                      <span class="icon">
                        <v-icon>credit_card</v-icon>
                      </span>
                      <span class="text"> ادفع الان </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </v-col>

          <v-col cols="12" sm="6" class="py-0 pl-0">
            <div
              class="form-img"
              :style="'background-image: url(' + contract.contract_thumb + ')'"
            >
              <div class="overlay d-flex justify-center align-center">
                <img
                  src="../assets/images/logoWhite.png"
                  width="300px"
                  alt="register-img"
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- success dialog -->
    <v-dialog v-model="paymentDialog" max-width="500">
      <v-card>
        <v-card-title class="headline main-color">
          برجاء الانتظار
        </v-card-title>

        <div>
          <v-container style="padding: 1rem !important">
            <v-row>
              <v-col>
                <p class="text-center grey--text darken">
                  سيتم تحويلك لصفحة الدفع خلال
                </p>
                <p class="text-center grey--text darken">{{ countDown }}</p>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { validationMixin } from "../mixins/validationMixin";
import EventService from "../Services/EventService";

export default {
  name: "Payment",
  mixins: [validationMixin],
  data() {
    return {
      valid: false,
      contract: {},
      jawabUrl: window.location.origin,
      paymentId: 1,
      paymentData: {
        payment_method: "visa",
        card_number: "",
        card_holder_name: "",
        cvc: "",
        exp_month: "",
        exp_year: "",
        cartID:
          localStorage.cartID && localStorage.cartID != undefined
            ? localStorage.cartID
            : "",
        address_id: 0,
        user_address: {
          address: "",
          user_city_id: "",
        },
      },
      months: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      years: ["2021", "2022", "2023", "2024", "2025"],
      loginObj: {
        email: "",
        password: "",
      },
      validationErrors: {},
      countDown: 5,
      paymentDialog: false,
      amount: 0,
    };
  },
  methods: {
    getCheckoutData() {
      
      EventService.getEvent(
        "/checkout/" + this.$route.params.order_id,
        true
      ).then((response) => {
        this.contract = response.data.data;
        this.amount = response.data.data.amount;
      });
    },
    submitCheckout() {
      if (this.valid == false) {
        this.validate();
      } else {
        EventService.postEvent(
          "/checkout/" + this.$route.params.order_id,
          {},
          true
        ).then((response) => {
          if (response.data.status.error == false) {
            this.paymentId = response.data.data.payment_id;
            this.paymentDialog = true;
            this.countDownTimer();
          }
        });
      }
    },

    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        document.getElementById("payment_form").submit();
        this.paymentDialog = false;
      }
    },
  },
  mounted() {
    this.getCheckoutData();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/global.scss";

.register-section {
  margin-top: 50px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;

  .contract-title {
    font-size: 2rem;
  }
  .form-section {
    padding: 20px 20px;
  }
}
.form-img {
  // background-image: url("../assets/images/register-img.jpg");
  width: 100%;
  height: 100%;
  background-size: cover;
  position: relative;

  .overlay {
    width: 100%;
    height: 100%;
    background-color: #0000006e;
  }
}

.back-error {
  margin-top: -18px;
  font-size: 14px;
}

.order-details {
  border-top: 1px dashed rgba(71, 185, 114, 0.6) !important;
  border-bottom: 1px dashed rgba(71, 185, 114, 0.6) !important;

  margin: 1.5rem 0;

  padding: 1rem 0;

  .name,
  .price {
    font-size: 1.5rem;
  }
}

.main-btn {
  padding: 0 !important;
  position: relative;
  .icon {
    background-color: #ffffff !important;
    padding: 4px 5px;
    color: $main-color !important;
    position: absolute;
    right: -1px;
  }
  .text {
    padding: 0 20px !important;
  }
}
</style>
