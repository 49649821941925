<template>
  <div id="account">
    <section class="header"></section>

    <section class="content">
      <v-container>
        <v-row>
          <v-col cols="4" sm="4">
            <div class="userCard px-6 py-6">
              <div class="userThumb text-center mb-4">
                <img
                  src="../assets/images/avatar.png"
                  alt=""
                  class="img-responsive"
                />
              </div>
              <div class="details" v-if="userData && userData.name">
                <v-form
                  @submit.prevent="save()"
                  v-model="valid"
                  class="reg-form"
                  ref="form"
                >
                  <ul class="list-unstyled">
                    <li>
                      <div class="content d-flex">
                        <p
                          class="ml-3"
                          :class="edit ? 'text-content-width' : ''"
                        >
                          <strong class="blue-text text-title">الاسم: </strong>
                          <span class="gray-color" v-if="!edit">{{
                            userData.name
                          }}</span>
                        </p>
                        <v-text-field
                          v-if="edit"
                          v-model="userData.name"
                          :rules="[validationRules.required]"
                          @focus="deleteValidation()"
                          type="text"
                          outlined
                          dense
                        ></v-text-field>
                      </div>
                    </li>
                    <li>
                      <div class="content d-flex">
                        <p
                          class="ml-3"
                          :class="edit ? 'text-content-width' : ''"
                        >
                          <strong class="blue-text">الايميل: </strong>
                          <span class="gray-color" v-if="!edit">{{
                            userData.email
                          }}</span>
                        </p>
                        <v-text-field
                          v-if="edit"
                          v-model="userData.email"
                          @focus="deleteValidation()"
                          :rules="[
                            validationRules.required,
                            validationRules.email,
                          ]"
                          type="text"
                          class="d-inline-block"
                          outlined
                          dense
                        ></v-text-field>
                      </div>
                      <p
                        class="error--text back-error"
                        v-if="
                          edit && validationErrors && validationErrors.email
                        "
                      >
                        {{ validationErrors.email[0] }}
                      </p>
                    </li>
                    <li>
                      <div class="content d-flex">
                        <p
                          class="ml-3"
                          :class="edit ? 'text-content-width' : ''"
                        >
                          <strong class="blue-text">الجوال: </strong>
                          <span class="gray-color" v-if="!edit">{{
                            userData.mobile
                          }}</span>
                        </p>
                        <v-text-field
                          v-if="edit"
                          v-model="userData.mobile"
                          @focus="deleteValidation()"
                          type="text"
                          :rules="[
                            validationRules.required,
                            validationRules.numeric,
                          ]"
                          class="d-inline-block"
                          outlined
                          dense
                        ></v-text-field>

                        <p
                          class="error--text back-error"
                          v-if="
                            edit && validationErrors && validationErrors.mobile
                          "
                        >
                          {{ validationErrors.mobile[0] }}
                        </p>
                      </div>
                    </li>
                    <li>
                      <div class="content d-flex">
                        <p
                          class="ml-3"
                          :class="edit ? 'text-content-width' : ''"
                        >
                          <strong class="blue-text">كلمة المرور: </strong>
                          <span class="gray-color" v-if="!edit">*********</span>
                        </p>
                        <v-text-field
                          v-if="edit"
                          v-model="userData.password"
                          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show ? 'text' : 'password'"
                          @click:append="show = !show"
                          class="d-inline-block"
                          outlined
                          dense
                        ></v-text-field>
                      </div>
                    </li>
                    <li class="text-center">
                      <v-btn
                        class="main-btn mt-4"
                        @click="editProfile()"
                        v-if="!edit"
                      >
                        <v-icon>edit</v-icon>
                        تعديل</v-btn
                      >
                      <v-btn class="main-btn mt-4" @click="save()" v-if="edit">
                        حفظ</v-btn
                      >
                      <v-btn
                        class="second-btn mt-4"
                        @click="cancel()"
                        v-if="edit"
                      >
                        الغاء</v-btn
                      >
                    </li>
                  </ul>
                </v-form>
              </div>
            </div>
          </v-col>
          <v-col cols="8" sm="8">
            <div class="contracts">
              <table style="width: 100%">
                <tr>
                  <th>العقود التى تم شرائها</th>
                  <th>الحالة</th>
                  <th>التاريخ</th>
                  <th>السعر</th>
                  <th>التحميل PDF</th>
                  <th>التحميل Word</th>
                </tr>
                <tr v-for="(contract, index) in contracts" :key="index">
                  <td class="main-color">
                    {{ contract.name }}
                  </td>
                  <td class="gray-color">{{ contract.status }}</td>
                  <td class="gray-color">{{ contract.date }}</td>
                  <td class="gray-color">{{ contract.price }} ر.س</td>
                  <td class="gray-color">
                    <a
                      :href="contract.download_url"
                      target="_blank"
                      class="main-color"
                      style="text-decoration: none"
                    >
                      <v-icon class="iconStyle">picture_as_pdf</v-icon>
                    </a>
                  </td>
                  <td class="gray-color">
                    <a
                      :href="contract.word_download_url"
                      target="_blank"
                      class="main-color"
                      style="text-decoration: none"
                      ><v-icon class="iconStyle">article</v-icon></a
                    >
                  </td>
                </tr>
              </table>
            </div>
            <v-row v-if="contracts.length > 0">
              <v-col>
                <div class="text-center my-4">
                  <v-pagination
                    v-model="page"
                    :length="total"
                    color="#30ba9f"
                  ></v-pagination>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { validationMixin } from "../mixins/validationMixin";
import EventService from "../Services/EventService";

export default {
  name: "Account",
  mixins: [validationMixin],
  data() {
    return {
      show: false,
      valid: false,
      edit: false,
      page: 1,
      total: 10,
      userData: {},
      contracts: [],
      serverUserData: {},
      validationErrors: {},
    };
  },
  watch: {
    page: {
      handler() {
        this.getAccountData();
      },
    },
  },
  methods: {
    editProfile() {
      this.edit = true;
    },
    getAccountData() {
      EventService.getEvent("/profile?page=" + this.page, true).then(
        (response) => {
          this.userData = response.data.data.user;
          this.contracts = response.data.data.contracts.data;
          this.page = response.data.data.contracts.current_page;
          this.total = response.data.data.contracts.last_page;
        }
      );
    },
    save() {
      if (this.valid == false) {
        this.validate();
      } else {
        EventService.postEvent("/profile", this.userData.user, true).then(
          (response) => {
            if (response.data.status.error == false) {
              this.edit = false;
            } else {
              this.validationErrors = response.data.status.validation_errors;
            }
          }
        );
      }
    },
    cancel() {
      this.getAccountData();
      this.edit = false;
    },
    deleteValidation() {
      this.validationErrors = {};
    },
  },
  mounted() {
    this.getAccountData();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/global.scss";
#account {
  margin-bottom: 50px;
}
.header {
  height: 200px;
  background-image: linear-gradient(to top, #30ba9f, #3e546d);
}
.content {
  .userCard {
    box-shadow: 0px 2px 5px #aaaaaa;
    border: 1px solid #ccc;
    border-top-left-radius: 40px;
    margin-top: -110px;
    background-color: #ffffff;
    img {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      display: inline-block;
      margin: 0 auto;
      border: 2px solid $main-color;
    }

    .details {
      .list-unstyled {
        padding-left: 0 !important;
        li {
          // margin-bottom: 10px;
          font-weight: bold;

          .main-btn,
          .second-btn {
            width: 100px;
            min-width: unset !important;
            display: inline-block !important;
            margin: 5px;
          }
          .text-content-width {
            width: 25%;
          }
        }
      }
    }
  }

  .contracts {
    box-shadow: 0px 2px 5px #aaaaaa;
    border: 1px solid #ccc;
    padding: 30px 30px;
    table,
    th,
    td {
      text-align: center;
      border: 1px solid #cccccc;
      border-collapse: collapse;
      font-weight: bold;
    }

    table th {
      color: $blue-text;
      padding: 10px 0;
      background-color: #eeeeee;
    }
    table td {
      padding: 10px 0;
    }
  }
}
</style>
