<template>
  <div>
    <div class="banner"></div>
    <v-row>
      <v-col cols="12" sm="6" offset-sm="3" class="register-section mb-8">
        <div class="form-section" v-if="paymentStatus == 'paid'">
          <p class="icon-item text-center">
            <v-icon x-large color="#30ba9f">check_circle_outline</v-icon>
          </p>
          <p class="text-center blue-text">تمت عملية الشراء بنجاح</p>
          <h2 class="text-center main-color">شكراً لك لإستخدام موقع عقود</h2>
          <img
            src="../assets/images/logo.png"
            class="img-responsive mx-auto mt-3 mb-4"
            width="200px"
            alt=""
          />
          <div v-if="url" class="text-center">
            <p class="blue-text">اضغط على الصيغة المطلوبة لتحميل العقد</p>
            <a
              class="main-color"
              style="text-decoration: none"
              :href="url"
              target="_blank"
            >
              <v-icon
                class="iconStyle"
                title="تحميل العقد PDF"
                style="font-size: 4em !important"
                >picture_as_pdf</v-icon
              ></a
            >
            <a
              class="main-color"
              style="text-decoration: none"
              :href="word_url"
              target="_blank"
            >
              <v-icon
                class="iconStyle"
                style="font-size: 4em !important"
                title="تحميل العقد Word"
                >article</v-icon
              ></a
            >
          </div>
          <div v-else>
            <p class="blue-text text-center">
              سيتم التواصل معك من قبل ادارة الموقع
            </p>
          </div>
        </div>
        <div
          class="form-section text-center"
          v-if="paymentStatus && paymentStatus == 'failed'"
        >
          <v-icon x-large color="red">highlight_off</v-icon>
          <p class="blue-text">فشلت عملية الدفع يرجى المحاولة مره اخرى</p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import EventService from "../Services/EventService";
import { validationMixin } from "../mixins/validationMixin";
export default {
  name: "thankYou",
  mixins: [validationMixin],
  data() {
    return {
      url: "",
      paymentStatus: "",
    };
  },
  methods: {
    getDownloadUrl() {
      EventService.postEvent(
        "/checkout/status/" + this.$route.params.order_id,
        {
          payment_id: this.$route.query.id,
          order_id:
            this.$route.query.order_id != undefined
              ? this.$route.query.order_id
              : null,
        },
        true
      ).then((response) => {
        this.paymentStatus = response.data.data.status;
        if (response.data.status.error == false) {
          console.log(response.data.data);
          this.url = response.data.data.download_url;
          this.word_url = response.data.data.word_download_url;
        }
      });
    },
  },
  mounted() {
    this.getDownloadUrl();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/global.scss";

.banner {
  background-image: url("../assets/images/banner/banner4.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 200px;
}

.register-section {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
  border: 1px solid $main-color;
  margin-top: -60px;
  border-top-left-radius: 50px;

  background-color: #fff;
  // margin-bottom: 50px;
  .form-section {
    padding: 10px;

    .v-icon {
      font-size: 6rem !important;
    }

    .main-btn {
      text-decoration: none;
    }
  }
}
.form-img {
  background-image: url("../assets/images/register-img.jpg");
  width: 100%;
  height: 100%;
  background-size: cover;
}

.back-error {
  margin-top: -18px;
  font-size: 14px;
}
</style>
