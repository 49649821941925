import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import HomeTest from "../views/HomeTest.vue";
import Contracts from "../views/contracts.vue";
import ContractsWithSearch from "../views/contractsWithSearch.vue";
import About from "../views/about.vue";
import Contact from "../views/contact.vue";
import ContractDetails from "../views/contractDetails.vue";
import Registration from "../views/registration.vue";
import Login from "../views/login.vue";
import Payment from "../views/payment.vue";
import thankyou from "../views/thank-you.vue";
import Account from "../views/account.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/test",
    name: "HomeTest",
    component: HomeTest,
  },
  {
    path: "/contracts",
    name: "Contracts",
    component: Contracts,
  },
  {
    path: "/allcontracts",
    name: "ContractsWithSearch",
    component: ContractsWithSearch,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/contract/:id",
    name: "ContractDetails",
    component: ContractDetails,
  },
  {
    path: "/thank-you/:order_id",
    name: "thankyou",
    component: thankyou,
  },
  {
    path: "/register",
    name: "Registration",
    component: Registration,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/payment/:order_id",
    name: "Payment",
    component: Payment,
  },
  {
    path: "/account",
    name: "Account",
    component: Account,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
