<template>
  <div class="body-inner">
    <div class="banner-area banner-img3">
      <div class="banner-text animated2">
        <!--<h3> عقد إيجار المنشآت التجارية   </h3>-->
      </div>
    </div>
    <!--/ banner end -->

    <div class="main-content">
      <section class="contact">
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <h3 class="column-title">تواصل معنا</h3>
              <v-form ref="form" id="contact-form">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="الاسم"
                      v-model="contactForm.name"
                      :rules="[validationRules.required]"
                      outlined
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      label="البريد لاالكتروني"
                      v-model="contactForm.email"
                      :rules="[validationRules.required, validationRules.email]"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <div class="form-group">
                  <v-textarea
                    label="الرسالة"
                    :rules="[validationRules.required]"
                    v-model="contactForm.message"
                    outlined
                  >
                  </v-textarea>
                </div>
                <div class="text-left">
                  <br />
                  <v-btn class="btn btn-primary" @click="submitForm">
                    ارسال
                  </v-btn>
                </div>
              </v-form>

              <h3 class="blue-text">شركة عقود</h3>
              <p class="blue-text">
                <v-icon color="#3e546d">place</v-icon>
                <span>{{ contactUsData.office_address }} </span>
              </p>
              <p class="blue-text" style="direction: ltr; text-align: right">
                <span>{{ contactUsData.phone }}</span>
                <v-icon color="#3e546d">stay_primary_portrait</v-icon>
              </p>
              <p class="blue-text">
                <v-icon color="#3e546d">email</v-icon>
                <span>{{ contactUsData.email }}</span>
              </p>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              class="map"
              v-html="contactUsData.contact_us_map_iframe"
            >
              <!-- <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3574.4601832337617!2d50.121876250469214!3d26.37631648924337!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e49e4ec8aa8d785%3A0xcf9e84698f353ef6!2sALEDAD%20TOWER!5e0!3m2!1sen!2seg!4v1582025802426!5m2!1sen!2seg"
                allowfullscreen=""
              ></iframe> -->
            </v-col>
          </v-row>
        </v-container>
      </section>
    </div>

    <v-snackbar v-model="snackbar" color="success" class="white--text" left>
      تم ارسال الرساله بنجاح

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          اغلاق
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { validationMixin } from "../mixins/validationMixin";
import EventService from "../Services/EventService";
export default {
  name: "About",
  mixins: [validationMixin],
  data() {
    return {
      contactUsData: "",
      contactForm: {
        name: "",
        email: "",
        message: "",
      },
      snackbar: false,
    };
  },
  methods: {
    getAboutData() {
      EventService.getEvent("/contact_us").then((response) => {
        this.contactUsData = response.data.data;
      });
    },
    submitForm() {
      EventService.postEvent("/contact_us", this.contactForm, true).then(
        (response) => {
          if (response.data.status.error == false) {
            console.log(response);
            this.snackbar = true;
          }
        }
      );
    },
  },
  mounted() {
    this.getAboutData();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/global.scss";

.banner-area {
  position: relative;
  min-height: 250px;
  color: #fff;
  background-position: 50% 50%;
  -webkit-background-size: cover;
  background-size: cover;
  -webkit-backface-visibility: hidden;
}

.banner-title {
  color: #fff;
  font-size: 3rem;
  font-weight: 400;
  -webkit-animation: fadeInLeft 0.5s ease-in 300ms both;
  animation: fadeInLeft 0.5s ease-in 300ms both;
}

.banner-text {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  max-width: 1170px;
  margin: 0 auto;
  width: 100%;
  z-index: 1;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.banner-img3 {
  background-image: url(../assets/images/banner/banner3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.animated2 {
  -webkit-animation: fadeInLeft 1.5s ease-in-out 300ms both;
  animation: fadeInLeft 1.5s ease-in-out 300ms both;
}
.main-content {
  min-height: 600px;
  background-color: #f3f3f3;
  padding-bottom: 30px;
  padding-top: 30px;
}

.map {
  z-index: 1;
  min-height: 450px;
}

iframe {
  border: solid 2px #ddd;
  border-radius: 7px;
  width: 100%;
  height: 400px;
}

.btn {
  border: 0;
  border-radius: 3px;
  padding: 12px 20px 10px;
  font-weight: 700;
  color: #fff;
  font-family: "Gess", sans-serif;
  -webkit-transition: 350ms;
  -moz-transition: 350ms;
  -o-transition: 350ms;
  transition: 350ms;
  line-height: 1rem;
  width: 150px;
}

.btn-primary {
  background: #3e546d;
  border: 2px solid #3e546d;
}
.theme--light.v-btn.v-btn--has-bg {
  background-color: #3e546d !important;
}
</style>
