<template>
  <v-app>
    <v-main>
      <navbar />
      <v-container class="default-container pa-0" fluid>
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </v-container>
      <Footer></Footer>
    </v-main>
  </v-app>
</template>

<script>
import navbar from "@/components/navbar.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "App",

  components: {
    navbar,
    Footer,
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
body {
  overflow: hidden;
}
.default-container {
  min-height: 75vh;
}

/*** TRANSITIONS ***/
.fade-enter {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}

.fade-leave-to {
  opacity: 0;
}
/* fade up */
.fade-up-enter {
  opacity: 0;
  transform: translateY(100px);
}

.fade-up-enter-active {
  transition: all 5s ease;
}

/*** End TRANSITIONS ***/
</style>
