<template>
  <div id="contracts">
    <section class="header">
      <div class="search d-flex align-center justify-center">
        <div class="cats_filters ml-8">
          <v-select
            :items="catItems"
            v-model="catIds"
            item-text="name"
            item-value="id"
            class="pt-0"
            multiple
            @change="getContracts('', catIds)"
            solo
          >
            <template v-slot:prepend-item>
              <v-list-item ripple @click="toggle">
                <v-list-item-action>
                  <v-icon :color="catIds.length > 0 ? 'indigo darken-4' : ''">
                    {{ icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title> الكل </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>
        </div>
        <v-text-field
          v-model="search"
          solo
          label=" اكتب  اسم العقد هنا"
          @keyup="getContracts(search)"
          append-icon="search"
        ></v-text-field>
      </div>
    </section>

    <section>
      <v-container>
        <v-row class="contracts-conent">
          <v-col cols="12" sm="3" class="">
            <v-row class="sidebar">
              <v-col sm="12">
                <p class="main-color">اختر الفئه</p>
                <v-checkbox
                  v-model="selectAll"
                  label="الكل"
                  class="my-0"
                  @change="selectAllItems('', catIds)"
                ></v-checkbox>
                <v-checkbox
                  v-for="(cat, i) in catItems"
                  :key="i"
                  v-model="catIds"
                  :label="cat.name"
                  :value="cat.id"
                  @change="getContracts('', catIds)"
                  class="my-0"
                ></v-checkbox>
                <!-- <p class="text-center">
                  <v-btn
                    class="sixth-btn"
                    v-if="selectAllCats || selectSomeCats"
                    @click="clearAll"
                    >حذف الكل
                  </v-btn>
                </p> -->
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="9" class="contracts">
            <v-row>
              <v-col
                cols="6"
                sm="3"
                class="py-0"
                v-for="(contract, i) in contractsData"
                :key="i"
              >
                <router-link
                  :to="`/contract/${contract.id}`"
                  class="contract-link mx-auto mb-6"
                >
                  <img
                    :src="contract.thumb"
                    :alt="contract.title"
                    class="img-responsive"
                  />
                  <p class="main-color mb-0 mt-2">{{ contract.title }}</p>
                  <p class="blue-text price">
                    {{ contract.price }}
                    ريال سعودى
                  </p>
                </router-link>
              </v-col>
            </v-row>

            <v-row v-if="contractsData.length > 0">
              <v-col>
                <div class="text-center my-4">
                  <v-pagination
                    v-model="page"
                    :length="total"
                    color="#30ba9f"
                  ></v-pagination>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <!-- <p class="text-center blue-text mt-4" v-else>لا توجد نتائج</p> -->
  </div>
</template>

<script>
import EventService from "../Services/EventService";

export default {
  name: "Contracts",
  data() {
    return {
      search: "",
      contractsCats: [],
      contractsData: [],
      panel: [],
      catItems: [],
      page: 1,
      total: 12,
      catItemsForCheckbox: [],
      selectAll: false,

      catIds: [],
    };
  },
  computed: {
    selectAllCats() {
      return this.catIds.length === this.catItems.length;
    },
    selectSomeCats() {
      return this.catIds.length > 0 && !this.selectAllCats;
    },
    icon() {
      if (this.selectAllCats) return "mdi-close-box";
      if (this.selectSomeCats) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.query.catId) {
          this.catIds = [Number(this.$route.query.catId)];
          this.getContracts("", this.catIds);
        } else {
          this.catIds = [];
          this.getContracts();
        }
      },
    },
    page: {
      handler() {
        this.getContracts("", this.catIds);
      },
    },
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllCats) {
          console.log("select null");
          this.catIds = [];
        } else {
          this.catItems.forEach((item) => {
            if (!this.catIds.includes(item.id)) {
              this.catIds.push(item.id);
            }
          });
        }

        this.getContracts("", this.catIds);
      });
    },
    clearAll() {
      this.catIds = [];
      console.log("clear All");
      this.getContracts("", this.catIds);
    },
    getCategories() {
      EventService.getEvent("/categories").then((response) => {
        this.catItems = response.data.data;
        this.catItemsForCheckbox = [...response.data.data];

        // let all = {
        //   id: "",
        //   name: "الكل",
        // };

        // this.catItemsForCheckbox.unshift(all);
      });
    },
    getContracts(search = "", catIds = []) {
      EventService.getEvent(
        "/contracts?search=" +
          search +
          "&category_id=" +
          catIds +
          "&page=" +
          this.page
      ).then((response) => {
        this.contractsCats = response.data.data;
        this.contractsData = response.data.data.data;

        this.page = response.data.data.current_page;
        this.total = response.data.data.last_page;
      });
    },

    selectAllItems() {
      if (this.selectAll) {
        this.catItems.forEach((item) => {
          if (!this.catIds.includes(item.id)) {
            this.catIds.push(item.id);
          }
        });

        this.getContracts("", this.catIds);
      } else {
        this.clearAll();
      }
    },
  },
  mounted() {
    this.getCategories();
    if (this.$route.query.search) {
      this.search = this.$route.query.search;
      this.getContracts(this.$route.query.search);
    } else if (this.$route.query.catId) {
      let catId = Number(this.$route.query.catId);
      this.catIds.push(catId);
      this.getContracts("", this.catIds);
    } else {
      this.getContracts();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/global.scss";

#contracts {
  background-color: $gray-background;
  .header {
    background-image: url(../assets/images/banner/banner2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 250px;
    height: 250px;
    color: #fff;
    background-position: 50% 50%;
    -webkit-background-size: cover;
    background-size: cover;

    .search {
      max-width: 60%;
      margin: 0 auto;
      position: relative;
      height: 100%;
    }
  }
  .contracts-conent {
    margin: 50px 0 0;
    .sidebar {
      background-color: #fff;
      padding: 10px !important;
      border: 1px solid #cccccc;
      p {
        font-weight: 700;
        font-size: 1.2rem;
      }
    }
    .contracts {
      background-color: #fff;
      padding: 50px 25px !important;
      border: 1px solid #cccccc;
      label.main-color {
        font-weight: bold;
      }
      .contract-link {
        display: block;
        border: 1px solid #ddd;
        width: 100%;
        min-height: 300px;
        font-size: 18px;
        margin-bottom: 10px;
        padding: 0;
        text-align: center;
        text-decoration: none;
        color: $blue-text;
        font-weight: bold;
        transition: all 0.4s;
        border-radius: 30px 0 0 0;
        overflow: hidden;
        p {
          font-size: 16px;
        }
      }
      .contract-link:hover {
        color: $main-color;
        border: 1px solid $main-color;
      }
    }
  }
}

@media (max-width: 600px) {
  #contracts {
    .header {
      .search {
        max-width: 80%;
      }
    }

    .contract-link {
      width: 100% !important;
    }
  }
}
</style>
