<template>
  <!--/ banner end -->

  <div class="main-content">
    <div class="banner-area banner-img1">
      <div class="banner-text animated2">
        <!--<h3> عقد إيجار المنشآت التجارية   </h3>-->
      </div>
    </div>
    <section class="Who">
      <div class="container">
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <div class="" v-html="about">
              <!-- <h2>الرؤية</h2>
              <p>
                نوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل
                ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه ...
                بروشور او فلاير على سبيل المثال ... او نماذج مواقع انترنت ...
              </p> -->
            </div>
          </v-col>

          <!-- <v-col cols="12" sm="12" md="4">
            <div class="">
              <h2>الرسالة</h2>
              <p>
                نوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل
                ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه ...
                بروشور او فلاير على سبيل المثال ... او نماذج مواقع انترنت ...
              </p>
            </div>
          </v-col>

          <v-col cols="12" sm="12" md="4">
            <div class="whoIntro animated2">
              <h2>الرؤية</h2>
              <p>
                نوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل
                ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه ...
                بروشور او فلاير على سبيل المثال ... او نماذج مواقع انترنت ...
              </p>
            </div>
          </v-col> -->
        </v-row>
      </div>
    </section>

    <section class="aboutUs">
      <div class="container">
        <v-row>
          <v-col cols="12" sm="12">
            <h2>مــا يميزنــا</h2>
          </v-col>

          <v-col cols="12" sm="3">
            <ul>
              <li>
                <v-icon class="iconsLayout">widgets</v-icon>صياغة محكمة للعقود
              </li>
              <li>
                <v-icon class="iconsLayout">work</v-icon>أعلى ضمان للحماية
              </li>
              <li>
                <v-icon class="iconsLayout">home</v-icon> سهولة التعامل عبر
                الانترنت
              </li>
              <li>
                <v-icon class="iconsLayout">event</v-icon> تقديم الدعم الفني
              </li>
              <li>
                <v-icon class="iconsLayout">info</v-icon> متابعة ما بعد التسجيل
              </li>
              <li>
                <v-icon class="iconsLayout">gavel</v-icon> استشارات قانونية
              </li>
            </ul>
          </v-col>

          <v-col cols="12" sm="6" class="center">
            <img :src="aboutUrl" />
          </v-col>

          <v-col cols="12" sm="3">
            <ul>
              <li>
                <v-icon class="iconsLayout">widgets</v-icon>صياغة محكمة للعقود
              </li>
              <li>
                <v-icon class="iconsLayout">work</v-icon>أعلى ضمان للحماية
              </li>
              <li>
                <v-icon class="iconsLayout">home</v-icon> سهولة التعامل عبر
                الانترنت
              </li>
              <li>
                <v-icon class="iconsLayout">event</v-icon> تقديم الدعم الفني
              </li>
              <li>
                <v-icon class="iconsLayout">info</v-icon> متابعة ما بعد التسجيل
              </li>
              <li>
                <v-icon class="iconsLayout">gavel</v-icon> استشارات قانونية
              </li>
            </ul>
          </v-col>
        </v-row>
      </div>
    </section>
  </div>
</template>

<script>
import EventService from "../Services/EventService";
export default {
  name: "About",
  data() {
    return {
      aboutUrl: require("../assets/images/about/about.png"),
      about: "",
    };
  },
  methods: {
    getAboutData() {
      EventService.getEvent("/about_us").then((response) => {
        this.about = response.data.data.about_us;
      });
    },
  },
  mounted() {
    this.getAboutData();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/global.scss";
.banner-img1 {
  background-image: url(../assets/images/banner/banner1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.banner-area {
  position: relative;
  min-height: 250px;
  color: #fff;
  background-position: 50% 50%;
  -webkit-background-size: cover;
  background-size: cover;
  -webkit-backface-visibility: hidden;
}

.whoIntro {
  color: #fff;
  background-color: #30ba9f;
  width: 300px;
  height: 300px;
  border-radius: 30px 0px 0px 0px;
  padding: 45px 10px;
  position: absolute;
  top: -89px;
}

.whoIntro h2 {
  color: #fff;
  text-align: center;
}
.animated2 {
  -webkit-animation: fadeInLeft 1.5s ease-in-out 300ms both;
  animation: fadeInLeft 1.5s ease-in-out 300ms both;
}
section,
.section-padding {
  padding: 20px 0;
  position: relative;
}

.Who {
  background-color: #f3f3f3;
}

/****** ABout US *****/

.aboutUs {
  background-color: #fff;
}

.aboutUs h2 {
  font-size: 30px;
  margin-bottom: 25px;
  color: #3e546d;
  text-align: center;
}

.aboutUs ul {
  margin-top: 15px;
  float: right;
  list-style: none;
  padding-right: 10px;
}

.aboutUs li {
  margin-bottom: 15px;
}

.aboutUs img {
  border-radius: 7px;
  width: 80%;
}

.center {
  text-align: center;
}

.aboutUs .iconsLayout {
  color: #30ba9f;
  font-size: 30px;
  margin-left: 15px;
}
</style>
