<template>
  <div id="contract-details">
    <div class="title text-center blue-background">
      <h3 class="py-3">{{ contractData.title }}</h3>
    </div>

    <div
      class="details"
      v-if="contractData.steps && contractData.steps.length > 0"
    >
      <v-row>
        <v-col cols="12" sm="3" order="2" order-sm="1" class="steps pa-0">
          <ul class="pl-0">
            <li
              v-for="(stepTitle, i) in contractData.steps"
              :key="i"
              :class="i == step ? 'active' : ''"
              @click="nextStep(i)"
            >
              {{ stepTitle }}
            </li>
          </ul>
          <!-- <div
            class="buttons py-4"
            v-if="
              this.contractData.steps_questions &&
              step + 1 == this.contractData.steps_questions.length
            "
          >
            <v-btn class="second-btn my-4 mr-3" @click="showContract()"
              >عرض العقد</v-btn
            >
            <v-btn class="third-btn my-4 mr-3" @click="submitContract"
              >تحميل العقد</v-btn
            >
          </div> -->
        </v-col>
        <v-col cols="12" sm="6" order="3" order-sm="2" class="questions">
          <v-form v-model="valid" ref="questionsForm">
            <h2 class="main-color mb-2">{{ activeSection.step }}</h2>
            <div
              class="question my-4"
              v-for="(question, questionIndex) in activeSection.questions"
              :key="questionIndex"
            >
              <div class="question-title px-3">
                <p class="title blue-text">
                  {{ question.question }}
                </p>
                <span class="snipet-icon">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="#3e546d" dark v-bind="attrs" v-on="on">
                        sticky_note_2
                      </v-icon>
                    </template>
                    <span>{{ question.description }}</span>
                  </v-tooltip>
                </span>
              </div>
              <div class="question-content pa-3">
                <v-text-field
                  v-model="question.answer"
                  outlined
                  dense
                  :rules="[validationRules.required]"
                >
                </v-text-field>
              </div>
            </div>

            <div class="buttons my-4 text-left">
              <v-btn
                class="main-btn ml-3"
                @click="nextStep()"
                v-if="
                  this.contractData.steps_questions &&
                  step < this.contractData.steps_questions.length - 1
                "
              >
                التالى
              </v-btn>
              <div class="d-flex justify-end" v-else>
                <v-btn
                  class="second-btn ml-3"
                  @click="showContract()"
                  :disabled="!valid"
                  >عرض العقد</v-btn
                >
                <v-btn
                  class="main-btn"
                  @click="submitContract()"
                  :disabled="!valid"
                >
                  تحميل العقد
                </v-btn>
              </div>

              <!-- <v-btn class="fourth-btn">تخطى</v-btn> -->
              <!-- :disabled="this.activeSection.questions && (step - 1 == this.activeSection.questions.length)" -->
            </div>
          </v-form>
        </v-col>
        <!-- <v-col cols="12" sm="3" order="1" order-sm="3">
          <div class="contract-info">
            <h4 class="info-title">
              <span>
                <v-icon class="main-color" large>emoji_objects</v-icon>
              </span>
              <span class="blue-text"> تعريف بهذه المرحلة </span>
            </h4>
            <p class="content">
              يكتب هنا معلومات مبسطة عن هذه المرحلة كتوجيه للمستخدم أثناء ملئ
              البيانات.
            </p>
          </div>
        </v-col> -->
      </v-row>
    </div>
    <div class="text-center py-8" v-else>
      <v-btn class="main-btn ml-3" @click="submitContract()">
        تحميل العقد
      </v-btn>
    </div>
  </div>
</template>

<script>
import EventService from "../Services/EventService";
import { validationMixin } from "../mixins/validationMixin";
export default {
  name: "ContractDetails",
  mixins: [validationMixin],
  data() {
    return {
      contractData: {},
      activeSection: {},
      step: 0,
      valid: false,
      shouldPay: true,
    };
  },
  methods: {
    getContractData(id) {
      if (id) {
        EventService.getEvent("/contracts/" + id).then((response) => {
          this.contractData = response.data.data;
          this.activeSection = this.contractData.steps_questions[this.step];
        });
      }
    },

    nextStep(step = "") {
      if (!this.valid) {
        this.$refs.questionsForm.validate();
        return;
      }
      this.$refs.questionsForm.resetValidation();
      this.contractData.steps_questions[this.step] = this.activeSection;
      if (step !== "") {
        console.log(step);
        this.step = step;
        this.activeSection = this.contractData.steps_questions[this.step];
      } else {
        if (this.step + 1 < this.contractData.steps_questions.length) {
          this.step += 1;
          this.activeSection = this.contractData.steps_questions[this.step];
        }
      }
    },

    submitContract() {
      if (this.shouldPay) {
        EventService.postEvent(
          "/contracts/" + this.$route.params.id,
          {
            steps_questions: this.contractData.steps_questions,
          },
          true
        ).then((response) => {
          if (response.data.status.error == false) {
            window.location = "/payment/" + response.data.data.order_id;
            // this.$router.push({
            //   path: "/payment/" + response.data.data.order_id,
            // });
          }
        });
      } else {
        EventService.postEvent(
          "/contracts/order/" + this.$route.query.order_id,
          {
            steps_questions: this.contractData.steps_questions,
          },
          true
        ).then((response) => {
          if (response.data.status.error == false) {
            window.location =
              "/thank-you/" +
              response.data.data.payment_id +
              "?order_id=" +
              this.$route.query.order_id;
            // this.$router.push({
            //   path: "/payment/" + response.data.data.order_id,
            // });
          }
        });
      }
    },
    showContract() {
      EventService.postEvent(
        "/contracts/showdesign/" + this.$route.params.id,
        {
          steps_questions: this.contractData.steps_questions,
        },
        true
      ).then((response) => {
        if (response.data.status.error == false) {
          // console.log(response.data);
          window.open(response.data.data.url, "_blank");
        }
      });
    },
  },
  mounted() {
    this.getContractData(this.$route.params.id);
    if (this.$route.query.order_id !== undefined) {
      EventService.getEvent(
        "/contracts/order/" + this.$route.query.order_id,
        true
      ).then((response) => {
        if (response.data.status.error == false) {
          this.shouldPay = response.data.data.shouldPay;
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/global.scss";

#contract-details {
  background-color: $gray-background;
  .title h3 {
    color: #ffffff !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .details {
    padding-right: 3rem;
    padding-left: 3rem;
    margin-top: 16px;
    .steps {
      background-color: #ffffff;
      ul {
        list-style: none;
        color: $blue-text;

        li {
          font-size: 18px;
          padding-top: 15px;
          padding-bottom: 15px;
          padding-right: 15px;
          color: $blue-text;
          border-bottom: 1px solid #ddd;
          background-color: #ffffff;
          font-weight: bold;
          cursor: pointer;
        }

        li.active {
          background-color: $gray-background;
        }
      }
    }

    .questions {
      .question {
        background-color: #ffffff;
        border: 1px solid #cccccc;
        border-radius: 5px;

        .question-title {
          padding: 10px;
          border-bottom: 1px solid #cccccc;
          position: relative;

          .title {
            font-weight: bold;
            font-size: 1rem !important;
            margin-bottom: 0 !important;
          }

          .snipet-icon {
            position: absolute;
            left: 24px;
            top: 12px;
          }
        }
      }
    }
  }

  .contract-info {
    margin-top: 100px;
    border: 1px solid #30ba9f;
    border-radius: 15px 0px 0px 0px;
    min-height: 250px;
    padding-top: 10px;

    .info-title {
      padding: 12px 12px;
    }
    .content {
      color: #9e9e9e;
      padding: 12px;
    }
  }
}
</style>
