<template>
  <div class="home">
    <section class="header">
      <div class="search d-flex align-center justify-center">
        <v-text-field
          v-model="search"
          label=" اكتب  اسم العقد هنا"
          append-icon="search"
          @keyup="getContracts('', search)"
          solo
        ></v-text-field>
        <!-- clearable -->
      </div>
    </section>
    <v-container>
      <v-row class="mt-8">
        <v-col cols="12" sm="3">
          <ul class="list-unstyled cats-list">
            <li @click="getContracts('')">الكل</li>
            <li
              v-for="(cat, index) in categories"
              :key="index"
              @click="getContracts(cat.id)"
            >
              <span class="name"> {{ cat.name }} </span>
              <span class="count"> ({{ cat.contracts_count }})</span>
            </li>
          </ul>
        </v-col>
        <v-col cols="12" sm="9">
          <!-- <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="search"
                label="بحث سريع"
                prepend-icon="search"
                @keyup="getContracts('', search)"
                clearable
              ></v-text-field>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              class="mb-8"
              v-for="(contract, index) in contracts"
              :key="index"
            >
              <!-- <v-hover v-slot="{ hover }" open-delay="200" class="contract-card"> -->
              <!-- :elevation="hover ? 16 : 2"
                :class="{ 'on-hover': hover }" -->
              <v-card
                class="mx-auto contract-card"
                max-width="100%"
                :to="'/contract/' + contract.id"
              >
                <v-img
                  class="white--text align-end"
                  height="300px"
                  :src="contract.thumb"
                >
                </v-img>

                <v-card-title class="contract-title pb-2 blue-text">{{
                  contract.title
                }}</v-card-title>

                <v-card-text class="text--primary">
                  <p class="main-color">{{ contract.price }} ريال سعودى</p>
                  <p class="desc">{{ contract.description }}</p>
                </v-card-text>
              </v-card>
              <!-- </v-hover> -->
            </v-col>
          </v-row>

          <v-row v-if="contracts.length > 0">
            <v-col>
              <div class="text-center my-4">
                <v-pagination
                  v-model="page"
                  :length="total"
                  color="#47b972"
                ></v-pagination>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import EventService from "../Services/EventService";
export default {
  name: "ContractsWithSearch",
  components: {},
  data() {
    return {
      search: "",
      categories: [],
      contracts: [],
      page: 1,
      total: 12,
    };
  },
  methods: {
    getCategories() {
      EventService.getEvent("/categories").then((response) => {
        this.categories = response.data.data;
      });
    },
    getContracts(catId = "", search = "") {
      console.log("here");
      EventService.getEvent(
        "/contracts?search=" + search + "&category_id=" + catId
      ).then((response) => {
        this.contracts = response.data.data.data;
        this.page = response.data.data.current_page;
        this.total = response.data.data.last_page;
      });
    },
  },
  mounted() {
    this.getCategories();

    this.getContracts();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/global.scss";

.header {
  background-image: url(../assets/images/banner/banner2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 250px;
  height: 250px;
  color: #fff;
  background-position: 50% 50%;
  -webkit-background-size: cover;
  background-size: cover;

  .search {
    max-width: 50%;
    margin: 0 auto;
    position: relative;
    height: 100%;
  }
}

@media (max-width: 600px) {
  .website {
    max-width: 100%;
  }
}

.cats-list {
  list-style: none;

  li {
    color: $main-color;
    padding: 7px 10px;
    font-size: 14px;
    line-height: 1.2;
    font-weight: 600;
    font-size: 1.3rem;
    cursor: pointer;
  }
  li:hover {
    color: $hover-color;
  }
  @media (max-width: 600px) {
    li {
      display: inline-block;
    }
  }
}

@media (min-width: 600px) {
  .cats-list {
    margin-top: 90px;
  }
}

.contract-card {
  .contract-title {
    word-break: normal !important;
    font-weight: 600;
  }
  .contract-title:hover {
    color: $main-color;
  }
  .desc {
    color: $gray-color;
  }
}
</style>
