import axios from "axios";
// import store from "../store";
const options = {
  // baseURL: "http://oqoud-backend.test/api", // store.getters.getApiUrl
  /* baseURL: "http://localhost/oqoud/api", */
  baseURL: "https://oqoud-backend.shifters.tech/api",

  headers: {},
  // params: {
  //   filters: {},
  // },
};

export default {
  getEvent(api, withCredentials = false) {
    if (withCredentials == true && localStorage.isLoggedIn) {
      options.headers.Authorization = "Bearer " + localStorage.token;
    }
    // if (filters != false) {
    //   options.params.filters = Object.assign({}, filters);
    // }
    // options.headers.locale = localStorage.locale;
    const apiClient = axios.create(options);
    return apiClient.get(api);
  },

  postEvent(api, dataObj, withCredentials = false) {
    if (withCredentials && localStorage.isLoggedIn) {
      options.headers.Authorization = "Bearer " + localStorage.token;
    }
    const apiClient = axios.create(options);
    return apiClient.post(api, dataObj);
  },
};
