// import moment from "moment";
export const validationMixin = {
  data() {
    return {
      // vuetify validation
      validationRules: {
        required: (v) => !!v || "هذا الحقل مطلوب",
        year: (v) => {
          if (v) {
            return (
              v.length == 2 ||
              v.length == 4 ||
              "should be a two-digit or four-digit year"
            );
          } else return true;
        },
        month: (v) => {
          if (v) {
            return v <= 12 || "Please enter valid month ";
          } else return true;
        },
        maxLength2: (v) => {
          if (v) {
            return (
              v.length <= 2 ||
              "You have reached your maximum limit of characters allowed"
            );
          } else return true;
        },
        maxLength4: (v) => {
          if (v) {
            return (
              v.length <= 4 ||
              "You have reached your maximum limit of characters allowed"
            );
          } else return true;
        },
        maxLength5: (v) => {
          if (v) {
            return (
              v.length <= 5 ||
              "You have reached your maximum limit of characters allowed"
            );
          } else return true;
        },
        maxLength50: (v) => {
          if (v) {
            return (
              v.length <= 50 ||
              "You have reached your maximum limit of characters allowed"
            );
          } else return true;
        },
        maxLength25: (v) => {
          if (v)
            return (
              v.length <= 25 ||
              "You have reached your maximum limit of characters allowed"
            );
          else return true;
        },
        maxLength16: (v) => {
          if (v)
            return v.length == 16 || "يجب ان يكون رقم البطاقه مكون من 16 رقم";
          else return true;
        },
        maxLength20: (v) => {
          if (v)
            return (
              v.length <= 20 ||
              "You have reached your maximum limit of characters allowed"
            );
          else return true;
        },
        maxLength3: (v) => {
          if (v)
            return (
              (v && v.length == 3) || "يجب ان يكون ال ccv مكون من تلاث ارقام"
            );
          else return true;
        },
        // numeric: v =>
        //   (v && /^\d+$/.test(v)) || "This field only accept numbers",
        numeric: (v) => {
          if (v) return (v && /^\d+$/.test(v)) || "مسموح فقط بالارقام";
          else return true;
        },
        percentage: (v) =>
          (v > 0 && v <= 100) || "The percentage must be between 0 and 100.",
        alphaNum: (v) =>
          (v && /^\w+$/.test(v)) || "Please enter a valid format",
        email: (v) => /.+@.+\..+/.test(v) || "ادخل بريد الكترونى صالح",
        phoneNum: (v) =>
          /^(\+?)[0-9]+$/.test(v) || "Please enter a valid format",
        notStartWithZero: (v) =>
          (v.length == 9 && v[0] != 0) ||
          "This field must be only 9 numbers and not start with 0",
        spaces: (v) =>
          /^(.*[^ ].*)/.test(v) || "This field shouldn't contain spaces only",
        password: (v) =>
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(v) ||
          "passwordValidation",
        imageValidation: (v) =>
          v.size < 2000000 || "Avatar size should be less than 2 MB!",
        minOne: (v) => v >= 1 || "minOneValidation",
      },
    };
  },

  methods: {
    //     resetValidation(tab = 0) {
    //       if (tab == 0) {
    //         this.$refs.form.resetValidation();
    //       } else if (tab == 1) {
    //         this.$refs.form1.resetValidation();
    //       } else if (tab == 2) {
    //         this.$refs.form2.resetValidation();
    //       } else if (tab == 3) {
    //         this.$refs.form3.resetValidation();
    //       } else if (tab == 4) {
    //         this.$refs.form4.resetValidation();
    //       }
    //     },
    validate(tab = 0) {
      if (tab == 0) {
        this.$refs.form.validate();
      } else if (tab == 1) {
        this.$refs.form1.validate();
      } else if (tab == 2) {
        this.$refs.form2.validate();
      } else if (tab == 3) {
        this.$refs.form3.validate();
      } else if (tab == 4) {
        this.$refs.form4.validate();
      }
    },
    //     checkMultiSelect(selectArray) {
    //       return selectArray.length == 0 ? "required" : true;
    //     },
    //     checkboxValidation(checkboxArray) {
    //       return checkboxArray.length == 0 ? "required" : true;
    //     },

    //     // passwordValidation(pass) {

    //     // },

    //     // eslint-disable-next-line @typescript-eslint/camelcase
    //     mobileRule(country_code, mobile) {
    //       // eslint-disable-next-line @typescript-eslint/camelcase
    //       if (country_code == 187) {
    //         return !/^((?!0)[0-9]{9})*$/.test(mobile)
    //           ? "This field must be only 9 numbers and not start with 0. "
    //           : mobile.length <= 5 || mobile.length >= 15
    //           ? "This field must be only 9 numbers and not start with 0."
    //           : true;
    //       } else {
    //         if (mobile != null) {
    //           return !/^([0-9])*$/.test(mobile)
    //             ? "Mobile Number must be between 5 to 15 digits. first"
    //             : mobile.length <= 5 || mobile.length >= 15
    //             ? "Mobile Number must be between 5 to 15 digits. second"
    //             : true;
    //         } else {
    //           return "Mobile Number must be between 5 to 15 digits.";
    //         }
    //       }
    //     },
    //     positiveNumber(val) {
    //       if (Number(val) && val < 0) {
    //         return "Only positive numbers allowed";
    //       }
    //       return true;
    //     },

    //     // startDateRule(start, end, str) {
    //     //   const startDate = moment(start, "DD/MM/YYYY");
    //     //   const endDate = moment(end, "DD/MM/YYYY");
    //     //   return startDate >= endDate ? str : true;
    //     // },

    checkDate(year, month) {
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;

      if (year && year <= currentYear && month <= currentMonth) {
        return "برجاء ادخال تاريخ بطاقة صالحة";
      } else {
        return true;
      }
    },

    //     saleLessThanPrice(price, discountPrice) {
    //       if (price && discountPrice) {
    //         price = Number(price);
    //         discountPrice = Number(discountPrice);
    //         if (discountPrice >= price) {
    //           return "Discount Price Must be Less than Price";
    //         } else {
    //           return true;
    //         }
    //       }
    //       return true;
    //     },

    //     requiredIfEmpty(value, items) {
    //       if (items.length == 0) {
    //         if (!value) {
    //           return "required";
    //         } else {
    //           return true;
    //         }
    //       } else {
    //         return true;
    //       }
    //     },

    checkcardName(cardName) {
      if (!new RegExp("\\w+\\s\\w+\\s\\w+.*").test(cardName)) {
        return "يجب ان يكون الاسم ثلاثى ";
      } else {
        return true;
      }
    },
  },
};
