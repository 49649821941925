<template>
  <div>
    <v-row class="mininav white--text px-16 py-2">
      <v-col cols="12" md="2" class="py-1">
        <router-link to="/">
          <img
            class="img-responsive logo"
            src="../assets/images/logo.png"
            alt=""
          />
        </router-link>
      </v-col>
      <v-col cols="12" sm="7" class="py-1 before-nav">
        <ul class="list-unstyled">
          <li><router-link to="/" class="nav-link">الرئيسية</router-link></li>
          <li>
            <router-link to="/about" class="nav-link">عن الموقع</router-link>
          </li>
          <li>
            <router-link to="/contact" class="nav-link">تواصل معنا</router-link>
          </li>
          <li class="social-icons">
            <a :href="homeData.fb_link" target="_blank" class="nav-link">
              <v-icon class="iconStyle">mdi-facebook</v-icon>
            </a>
            <a :href="homeData.tw_link" target="_blank" class="nav-link">
              <v-icon class="iconStyle">mdi-twitter</v-icon>
            </a>
            <!-- <a href="#" target="_blank" class="nav-link">
              <v-icon class="iconStyle">mdi-linkedin</v-icon>
            </a> -->
            <a :href="homeData.insa_link" target="_blank" class="nav-link">
              <v-icon class="iconStyle">mdi-instagram</v-icon>
            </a>
          </li>
        </ul>
      </v-col>

      <v-col cols="12" md="3" class="login-buttons mt-1" v-if="!isLoggedIn">
        <v-btn class="main-btn" @click="loginDialog = true">تسجيل الدخول</v-btn>
      </v-col>
      <v-col cols="12" md="3" class="login-buttons mt-1" v-if="isLoggedIn">
        <router-link to="/account" class="nav-link d-inline-block ml-3">
          <v-icon color="#3e546d">account_circle</v-icon>
          <span> حسابى </span>
        </router-link>
        <v-btn class="main-btn" @click="logout()">تسجيل الخروج</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <!--  <v-toolbar dense color="#30ba9f" class="navbar">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title>Title</v-toolbar-title> 

        <ul class="list-unstyled navbar-menu">
          <li>
            <router-link to="contracts" class="router-link mr-8"
              >العقود</router-link
            >
          </li> 
           <li>
             <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  class="dropdown-btn"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  <span class="text">عقود العقارات</span>
                  <v-icon>expand_more</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in items" :key="index">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu> 
          </li> 

          <li v-for="(cat, index) in catItems" :key="index">
            <router-link
              :to="`/contracts?catId=${cat.id}`"
              class="nav-link d-inline-block mx-3"
              >{{ cat.name }}</router-link
            >
          </li>
        </ul> 

        <v-spacer></v-spacer>
      </v-toolbar> -->
      <v-navigation-drawer
        v-model="drawer"
        right
        absolute
        temporary
        v-if="drawer == true"
      >
        <v-list nav dense>
          <v-list-item-group active-class="deep-purple--text text--accent-4">
            <v-list-item>
              <v-list-item-title>
                <router-link to="/" class="router-link">الرئيسية</router-link>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <router-link to="/about" class="router-link"
                  >عن الشركه</router-link
                >
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <router-link to="/contact" class="router-link"
                  >تواصل معنا</router-link
                >
              </v-list-item-title>
            </v-list-item>

            <v-list-item v-for="(cat, index) in catItems" :key="index">
              <v-list-item-title>
                <router-link
                  :to="`/contracts?catId=${cat.id}`"
                  class="nav-link d-inline-block mx-3"
                  >{{ cat.name }}</router-link
                >
              </v-list-item-title>
            </v-list-item>

            <v-list-item class="text-center" v-if="!isLoggedIn">
              <v-btn class="main-btn mx-auto" @click="loginDialog = true"
                >تسجيل الدخول</v-btn
              >
            </v-list-item>
            <v-list-item class="text-center" v-if="isLoggedIn">
              <v-btn class="main-btn text-center" @click="logout()"
                >تسجيل الخروج</v-btn
              >
            </v-list-item>
            <v-list-item class="text-center" v-if="isLoggedIn">
              <router-link to="/account" class="nav-link d-inline-block ml-3">
                <v-icon color="#3e546d">account_circle</v-icon>
                <span> حسابى </span>
              </router-link>
            </v-list-item>

            <v-list-item class="social-icons">
              <a href="#" target="_blank" class="nav-link">
                <v-icon class="iconStyle">mdi-facebook</v-icon>
              </a>
              <a href="#" target="_blank" class="nav-link">
                <v-icon class="iconStyle">mdi-twitter</v-icon>
              </a>
              <a href="#" target="_blank" class="nav-link">
                <v-icon class="iconStyle">mdi-linkedin</v-icon>
              </a>
              <a href="#" target="_blank" class="nav-link">
                <v-icon class="iconStyle">mdi-instagram</v-icon>
              </a>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </v-row>
    <Registration
      :dialog="registrationDialog"
      @close="closeRegistrationDialog"
    />
    <Login
      :dialog="loginDialog"
      @close="closeLoginDialog"
      @openRegistrationDialog="openRegistrationDialog()"
    />
  </div>
</template>

<script>
import EventService from "../Services/EventService";

import Registration from "@/views/registration.vue";
import Login from "@/views/login.vue";
export default {
  components: {
    Registration,
    Login,
  },
  data() {
    return {
      registrationDialog: false,
      loginDialog: false,
      drawer: false,
      group: null,
      items: [
        { title: "عقود العقارات" },
        { title: "عقود العقارات" },
        { title: "عقود العقارات" },
        { title: "عقود العقارات" },
      ],
      catItems: [],
      isLoggedIn:
        localStorage.isLoggedIn != undefined &&
        localStorage.isLoggedIn == "true"
          ? true
          : false,
      homeData: [],
    };
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  methods: {
    openRegistrationDialog() {
      this.loginDialog = false;
      this.registrationDialog = true;
    },
    closeRegistrationDialog() {
      this.registrationDialog = false;
    },
    closeLoginDialog() {
      this.loginDialog = false;
    },
    logout() {
      EventService.getEvent("/logout", true).then((response) => {
        if (response.data.status.error == false) {
          localStorage.clear();
          window.location = "/";
        }
      });
    },
    getCategories() {
      EventService.getEvent("/categories").then((response) => {
        this.catItems = response.data.data;
      });
    },
    getHomeData() {
      EventService.getEvent("/home/data").then((response) => {
        this.homeData = response.data.data;
      });
    },
  },
  mounted() {
    this.getCategories();
    this.getHomeData();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/global.scss";

.mininav {
  background-color: #cac9ca;
  background-position: center;
  background-size: cover;

  .logo {
    // max-height: 60px !important;
    margin: 5px 0;
  }

  ul {
    li {
      display: inline-block;
      margin: 5px 0;
      padding: 10px 15px;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      .nav-link {
        text-decoration: none;
        color: $blue-text;
        font-weight: bold;
        transition: all 0.4s;
      }
      .nav-link:hover {
        color: $main-color;
      }
    }
  }

  .social-icons {
    .nav-link {
      padding-right: 5px;
      padding-left: 5px;
      .v-icon {
        color: $blue-text;
        transition: all 0.4s;
      }
    }

    .nav-link:hover .v-icon {
      color: $main-color;
    }
  }
  .login-buttons {
    .nav-link {
      text-decoration: none;
      color: $blue-text;
      font-weight: bold;
      transition: all 0.4s;
    }
    .main-btn {
      width: 120px !important;
    }
  }
}

.main-btn {
  color: #ffffff !important;
}

.navbar {
  z-index: 2;
  ul {
    li {
      display: inline-block !important;

      .nav-link,
      .router-link {
        text-decoration: none;
        color: #ffffff;
        font-weight: bold;
      }
    }
  }
  .dropdown-btn {
    height: 36px;
    min-width: 64px;
    padding: 0 16px;
    background-color: transparent !important;
    border: 0;
    box-shadow: unset;

    .text {
      color: #ffffff;
      font-weight: bold;
    }
    .v-icon {
      color: #ffffff;
    }
  }
}

/* nav menu */
.v-menu__content {
  border-top: 2px solid #3e546d;
  border-radius: 0;
}

.v-list-item {
  border-bottom: 1px solid #cccccc;
  color: $blue-text;
  // font-weight: bold;
}

@media (max-width: 600px) {
  /* navbar */
  .mininav {
    img {
      max-width: 150px;
      display: block;
      margin: 0 auto !important;
    }
    ul {
      li {
        padding: 5px 7px;
        .nav-link {
          font-size: 14px;
        }
      }
    }

    .before-nav {
      display: none !important;
    }

    .login-buttons {
      display: none;
    }
  }

  .navbar {
    .navbar-menu {
      display: none !important;
    }
  }
  /* drawer */
  .v-navigation-drawer {
    z-index: 3333;
    .dropdown-btn {
      width: 100% !important;
      padding: 0 16px;
      background-color: transparent !important;
      border: 0;
      box-shadow: unset;

      .text {
        color: $blue-text;
        font-weight: bold;
      }
      .v-icon {
        color: $blue-text !important;
      }
    }

    .nav-link,
    .router-link {
      text-decoration: none;
      display: inline-block;
      width: 100%;
      color: $blue-text;
      font-weight: bold;
      text-align: center;
    }

    .social-icons {
      .nav-link {
        padding-right: 5px;
        padding-left: 5px;
        .v-icon {
          color: $blue-text;
          transition: all 0.4s;
        }
      }

      .nav-link:hover .v-icon {
        color: $main-color;
      }
    }
  }
}
</style>
