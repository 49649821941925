<template>
  <div>
    <div class="banner">
      <!-- <div class="banner-title animated2">
          <h1>
            قم بإنشاء المستندات والعقود القانونية <br />عبر الإنترنت في دقائق
          </h1>
        </div> -->

      <div class="videobg">
        <video autoplay loop muted id="video-background">
          <source src="../assets/video/banner-video.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
    <!--/ banner end -->
    <section class="call-to-action-box no-padding">
      <v-container class="py-0">
        <v-col class="py-0">
          <div class="action-style-box">
            <v-row class="text-center">
              <v-col class="py-0">
                <h3 class="action-title">ابدأ البحث عن العقد</h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="9" class="py-0">
                <v-text-field
                  label=" اكتب  اسم العقد هنا"
                  append-outer-icon="search"
                  class="white-border-input"
                  v-model="search"
                  @keyup.enter="goToSearch()"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="py-0">
                <v-btn class="main-btn" @click="goToSearch()">
                  بحث عن العقد
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="">
                <router-link to="/contracts" class="router-link"
                  >عرض جميع العقود -></router-link
                >
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-container>
      <!-- Action style box -->
      <!-- Container end -->
    </section>
    <!-- Action end -->

    <!-- services section -->
    <section id="services" class="services">
      <div class="service-back"></div>
      <v-container>
        <v-row>
          <v-col>
            <h3 class="service-header text-center">خدماتنـــا</h3>
            <v-row>
              <v-col cols="12" sm="4">
                <div class="single-service">
                  <div class="work-icon">
                    <v-icon>groups</v-icon>
                  </div>
                  <div class="Val-desc">
                    <h3>استشارات</h3>
                    <p>تحت إشراف نخبة من أفضل المستشارين القانونين .</p>
                  </div>
                  <a href="#" class="Val-btn"> </a>
                </div>
              </v-col>
              <!-- end col -->

              <v-col cols="12" sm="4">
                <div class="single-service">
                  <div class="work-icon">
                    <v-icon>assignment</v-icon>
                  </div>
                  <div class="Val-desc">
                    <h3>العقود القانونية</h3>
                    <p>تقديم كل أنواع العقود القانونية بأفضل صياغة.</p>
                  </div>
                  <a href="#" class="Val-btn"> </a>
                </div>
              </v-col>
              <!-- end col -->

              <v-col cols="12" sm="4">
                <div class="single-service">
                  <div class="work-icon">
                    <v-icon>work</v-icon>
                  </div>
                  <div class="Val-desc">
                    <h3>تسجيل العقود</h3>
                    <p>تسجيل ومتابعة مراحل ما بعد كتابة العقد.</p>
                  </div>
                  <a href="#" class="Val-btn"> </a>
                </div>
              </v-col>
              <!-- end col -->
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section class="howItWorks">
      <v-container>
        <v-row>
          <v-col cols="12">
            <h2 class="white--text text-center sec-title">
              خطوات الحصول على العقد
            </h2>
          </v-col>
          <v-col cols="6" sm="3">
            <div class="item text-center white--text">
              <img
                src="../assets/images/icons/contract.svg"
                height="80px"
                alt=""
              />
              <h4 class="item-title">{{ steps[0]["title"] }}</h4>
              <p class="desc">{{ steps[0]["description"] }}</p>
            </div>
          </v-col>
          <v-col cols="6" sm="3">
            <div class="item text-center white--text">
              <img
                src="../assets/images/icons/answer.svg"
                height="80px"
                alt=""
              />
              <h4 class="item-title">{{ steps[1]["title"] }}</h4>
              <p class="desc">{{ steps[1]["description"] }}</p>
            </div>
          </v-col>
          <v-col cols="6" sm="3">
            <div class="item text-center white--text">
              <img src="../assets/images/icons/pay.svg" height="80px" alt="" />
              <h4 class="item-title">{{ steps[2]["title"] }}</h4>
              <p class="desc">{{ steps[2]["description"] }}</p>
            </div>
          </v-col>
          <v-col cols="6" sm="3">
            <div class="item text-center white--text">
              <img
                src="../assets/images/icons/download.svg"
                height="80px"
                alt=""
              />
              <h4 class="item-title">{{ steps[3]["title"] }}</h4>
              <p class="desc">
                {{ steps[3]["description"] }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!-- specilizations -->
    <!-- <section class="spec">
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <h2>نحن متخصصون في ..</h2>

            <v-expansion-panels accordion>
              <v-expansion-panel v-for="(item, i) in specialities" :key="i">
                <v-expansion-panel-header>{{
                  item.name
                }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  {{ item.description }}
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>

          <v-col cols="12" sm="6">
            <img
              class="img-responsive"
              src="../assets/images/spec.jpg"
              alt=""
            />
          </v-col>
        </v-row>
      </v-container>
    </section> -->

    <section id="our-clients" class="our-clients">
      <v-container fluid class="pb-0">
        <v-row>
          <!-- <v-col>
            <h2>تعرف على عملاؤنا</h2>
          </v-col> -->
        </v-row>
        <v-row class="mb-5 px-5">
          <v-col cols="6" sm="2" class="px-0 img-col">
            <img class="img-responsive" src="../assets/images/logo-1.png" />
          </v-col>
          <v-col cols="6" sm="2" class="px-0 img-col">
            <img class="img-responsive" src="../assets/images/logo2.png" />
          </v-col>
          <v-col cols="6" sm="2" class="px-0 img-col">
            <img class="img-responsive" src="../assets/images/logo3.png" />
          </v-col>
          <v-col cols="6" sm="2" class="px-0 img-col">
            <img class="img-responsive" src="../assets/images/logo4.png" />
          </v-col>
          <v-col cols="6" sm="2" class="px-0 img-col">
            <img class="img-responsive" src="../assets/images/logo-5.png" />
          </v-col>
          <v-col cols="6" sm="2" class="px-0 img-col">
            <img class="img-responsive" src="../assets/images/logo-6.png" />
          </v-col>
        </v-row>
      </v-container>
    </section>
    <!-- Clients end -->
  </div>
</template>

<script>
import EventService from "../Services/EventService";
export default {
  name: "Home",

  data() {
    return {
      search: "",
      steps: [],
    };
  },
  methods: {
    goToSearch() {
      // this.$router.push({ name: "Contracts", params: { search: this.search } });
      this.$router.push({ path: "/contracts?search=" + this.search });
    },
    getHomeSections() {
      EventService.getEvent("/home/sections").then((response) => {
        this.steps = response.data.data;
      });
    },
  },
  mounted() {
    this.getHomeSections();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/global.scss";
.img-col{
  text-align: center !important;
}
.img-responsive{
  display: inline-block  !important;
}
.banner {
  width: 100%;
  min-height: 550px;
  max-height: 550px;
  color: #fff;
  background-size: cover;
  overflow: hidden;
  video {
    // height: 480px;
    width: 100%;
  }
  #video-background {
    width: 100%;
  }
}

.call-to-action-box {
  margin-top: -17%;
  z-index: 33;
  position: relative;

  .action-style-box {
    background: $blue-background;
    padding: 18px 30px;
    width: 75%;
    margin-right: auto;
    margin-left: auto;
    border-radius: 30px 0px 0px 0px;
    text-align: center;
    .action-title {
      color: #fff;
      margin-bottom: 20px;
      margin-top: 20px;
      line-height: 36px;
      font-size: 30px;
      text-transform: uppercase;
      text-align: center;
    }

    .router-link {
      text-align: center;
      color: $main-color;
      font-size: 20px;
      text-decoration: none;
      font-weight: bold;
    }
  }
}

.services {
  position: relative;
  .container {
    position: relative;
    .service-header {
      z-index: 1111;
    }

    .single-service {
      text-align: center;
      width: 100%;
      background-color: rgb(255, 255, 255);
      padding: 20px;
      position: relative;
      border-radius: 20px 0px 0px;
      -webkit-box-shadow: 2px 10px 20px -10px rgb(0 0 0);
      -moz-box-shadow: 2px 10px 20px -10px rgb(0, 0, 0);
      box-shadow: 2px 10px 20px -10px rgb(0 0 0);
      min-height: 225px;

      .work-icon {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin: 0 auto;
        margin-bottom: 10px;
        padding-top: 25px;
        background-color: $main-color;

        .v-icon {
          font-size: 40px;
          color: #fff;
        }
      }
    }
  }
  .service-back {
    background-color: $main-color;
    position: absolute;
    height: 180px;
    width: 100%;
    top: 0px;
  }
}

.howItWorks {
  background-image: url("../assets/images/banner/how_back.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 400px;
  margin: 50px 0;

  .sec-title {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .item {
    .item-title {
      color: #ffffff;
      font-size: 1.5rem;
      margin: 10px 0;
    }
  }
}

.spec {
  margin: 50px 0 25px;
  h2 {
    color: $blue-text;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 30px;
  }
}

/* responsive */

@media (max-width: 600px) {
  .banner {
    min-height: 200px !important;
  }

  .call-to-action-box {
    margin-top: 0 !important ;
    margin-bottom: 30px;

    .action-style-box {
      width: 90%;
      .action-title {
        font-size: 20px;
      }
    }
  }
}
</style>
