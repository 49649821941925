<template>
  <div id="login">
    <v-dialog
      v-model="dialog"
      class="reg-dialog"
      width="500"
      transition="dialog-top-transition"
      persistent
    >
      <v-card>
        <v-card-title class="text-center dialog-title justify-center">
          <span class="">دخول مستخدم </span>

          <span class="icon">
            <v-btn icon @click="close()">
              <v-icon>close</v-icon>
            </v-btn>
          </span>
        </v-card-title>

        <v-divider></v-divider>
        <v-card-text class="mt-6">
          <p class="error--text back-error text-center">
            {{ validationErrors }}
          </p>
          <v-form
            @submit.prevent="submit"
            v-model="valid"
            class="reg-form"
            ref="form"
          >
            <v-row>
              <v-col cols="12" class="py-0">
                <label for="email" class="label">البريد الالكترونى </label>
                <v-text-field
                  v-model.trim="loginObj.email"
                  label=""
                  :rules="[validationRules.required, validationRules.email]"
                  @focus="deleteValidation()"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <label for="email" class="label">كلمة المرور</label>
                <v-text-field
                  v-model="loginObj.password"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  label=""
                  :type="show ? 'text' : 'password'"
                  :rules="[validationRules.required]"
                  @click:append="show = !show"
                  @focus="deleteValidation()"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center py-6">
          <!-- <v-spacer></v-spacer> -->
          <v-btn class="fourth-btn" @click="submit"> دخول </v-btn>
          <v-btn class="main-btn" @click="openRegistrationDialog()">
            تسجيل
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "../mixins/validationMixin";
import EventService from "../Services/EventService";
export default {
  name: "Login",
  mixins: [validationMixin],
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      valid: false,
      show: false,
      loginObj: {
        email: "",
        password: "",
      },
      validationErrors: "",
    };
  },
  methods: {
    openRegistrationDialog() {
      this.$emit("openRegistrationDialog");
    },
    close() {
      this.$emit("close");
    },
    submit() {
      if (this.valid == false) {
        this.validate();
      } else {
        EventService.postEvent("/login", this.loginObj).then((response) => {
          if (response.data.status.error == false) {
            localStorage.token = response.data.data.token;
            localStorage.isLoggedIn = true;
            window.location = "/";
          } else {
            this.validationErrors = response.data.status.message;
          }
        });
      }
    },
    deleteValidation() {
      this.validationErrors = "";
    },
  },
};
</script>

<style lang="scss">
@import "../assets/css/global.scss";

.dialog-title {
  font-size: 22px !important;
  color: #3e546d !important;
  position: relative;
  font-weight: 700 !important;

  .icon {
    position: absolute;
    left: 20px;
  }
}

.reg-form {
  // margin-top: 20px;

  .label {
    color: $blue-text !important;
    font-weight: 700 !important;
  }

  .back-error {
    margin-top: -20px !important;
  }
}
</style>
