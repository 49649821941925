<template>
  	<footer id="footer" class="footer">
			

			<div class="copyright">
				
        <v-container>
          <div class="row">
            <v-row>
            
                <v-col cols="12" xs="12" sm="6">
                	<div class="copyright-info">
                    <span>جميع الحقوق محفوظة   © 2021 <b> عقود  </b> </span>
                  </div>
                </v-col>
						
              <v-col cols="12" xs="12" sm="6">
                  <div class="footer-menu">
                      <ul class="nav unstyled">
                          <li><a href="index.html">   الرئيسية   </a></li>
                          <li><a href="aboutUs.html">   عن الشركة   </a></li>
                          <li><a href="#">خدماتنا     </a></li>
                          <li><a href="contactUs.html">تواصل معنا     </a></li>
                      </ul>
                  </div>
               </v-col>
            </v-row>
						
					</div><!-- Row end -->

        </v-container>
					

					<div id="back-to-top" data-spy="affix" data-offset-top="10" class="back-to-top affix">
						<button class="btn btn-primary" title="Back to Top">
							<i class="fa fa-angle-double-up"></i>
						</button>
					</div>

			</div><!-- Copyright end -->

		</footer><!-- Footer end -->
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>

/*-- Copyright --*/

.copyright {
    background: #3e546d;
    color: #fff;
    padding-top: 25px;
    position: relative;
    z-index: 1;
    font-size: 14px;
}
.footer-menu a {
    text-decoration: none;
}

.footer-menu {
    float: right;
}

.footer-menu ul li {
    display: inline-block;
    line-height: 12px;
    padding-left: 15px;
}

.footer-menu ul.nav li a {
    background: none;
    color: #fff;
    padding: 0;
}

.footer-menu ul li a:hover {
    color: #30ba9f;
}
</style>
